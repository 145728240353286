<template>
  <div class="ac1">
    <div class="banner">
      <img src="/imgs/01.jpg" alt="" />
    </div>
    <div class="container">
      <!-- 资讯区块2 -->
      <div class="ac2">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 资讯区块1 -->
          <div class="ac22">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>收费资讯</h2>
              <h4></h4>
            </div>

            <!-- 循环项 -->
            <div
              class="a1-item"
              v-for="(item, index) in this.myData"
              :key="index"
              @click="gopay()"
            >
              <!-- 标题 -->
              <div class="title">
                {{ item.title }}
              </div>
              <!-- 中层 -->
              <div class="item-con">
                <img :src="item.img" alt="" />
                <div class="item-jj">
                  {{ item.jj }}
                </div>
              </div>
              <!-- 信息层 -->
              <div class="item-info">
                <!-- 左侧 -->
                <div class="item-l">
                  <span>{{ item.author }}</span>
                  <span> ({{ item.sub }})阅读 </span>
                </div>
                <!-- 右侧 -->
                <div class="item-r">收费阅读:1元/篇</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="width: 275px" src="/imgs/pay.png" />
        <h2>扫码付费阅读：1元</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  name: "ac1",
  components: {
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      mAc: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "北京永逸人力资源有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.slice(0, 20);
        this.ac1 = data.slice(0, 5);
        this.ac2 = data.slice(5, 10);
        this.mAc = data.slice(0, 7);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇1元，点击确定购买");
      this.showModal = true;

      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条2元，点击确定登录购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
    // 登录跳转
    goLogin() {
      alert("资讯每篇1元，点击确定购买");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  // padding-top: 80px;
  box-sizing: border-box;
  .banner {
    width: 100%;
    height: 450px;
    margin: 10px 60;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    // 资讯区块2
    .ac2 {
      width: 100%;
      // height: 800px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;
        // background-color: #ff6600;
        // margin-left: 30px;
        //   资讯区块1
        .ac22 {
          width: 100%;
          // height: 600px;

          margin-top: 30px;
          // 标题
          .a1-title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;
            h2 {
              color: #333;
            }
          }

          // 循环项
          .a1-item {
            width: 100%;
            height: 200px;
            padding: 5px;
            box-sizing: border-box;
            margin: 30px 0;
            transition: all 0.2s;
            border: 2px solid #fff;

            padding: 10px 20px;
            box-sizing: border-box;
            &:hover {
              // box-shadow: 0px 0px 8px #888;
              // transform: translateY(-10px);
              background-color: #fff;
              border: 2px dashed #ccc;
              .title {
                color: $colorZ;
              }
            }
            //   标题
            .title {
              width: 100%;
              font-size: 14px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: all 0.2s;
              &:hover {
                color: $colorZ;
              }
            }
            //   中层
            .item-con {
              width: 100%;
              height: 120px;
              margin: 10px 0;
              // background: #ff5544;
              display: flex;
              align-items: center;
              // 图品
              img {
                width: 200px;
                height: 100%;
              }
              .item-jj {
                margin-left: 20px;
                width: 60%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 12px;
                color: #888;
                line-height: 30px;
              }
            }
            //   信息层
            .item-info {
              height: 30px;
              width: 100%;
              // background-color: #451215;

              @include flex();
              .item-l {
                span {
                  color: #888;
                  font-size: 12px;
                  margin-right: 30px;
                  i {
                    font-size: 14px;
                    margin-right: 5px;
                  }
                }
              }
              .item-r {
                font-size: 12px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}
</style>
